.blockInfo {
  border-left: 1px solid rgb(204, 204, 204);
  margin-left: 20px;
  padding-left: 10px;
  margin-top: 10px;
}
.itemInfo {
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.itemLabel {
  /* color: rgb(249, 149, 75); */
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}
.mission{
  white-space: break-spaces;
}
.itemValue {
  opacity: 1;
  display: block;
  /* width: 100%; */
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border-bottom: 1px solid #ced4da;
  /* border-radius: 0.25rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.itemValue.None {
  border-bottom: none;
}
