.navDiv {
  position: relative;
  margin: 0px 16px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.navDivServices {
  position: relative;
  margin: 0px 16px;
}
.navDivCarière {
  position: relative;
  margin: 0px 16px;
}
.navLink {
  font-size: 16px;
  color: #393939;
  font-family: Microsoft PhagsPa;
  font-weight: 700;
  cursor: pointer;
}
.navLink:hover {
  color: #f9954b !important;
}
.navBtn {
  margin-right: "1rem" !important;
  margin-left: "5rem" !important;
  background-color: "#4096e9" !important;
  padding-right: "0.5rem" !important;
  padding-left: "0.5rem" !important;
  font-size: "11pt" !important;
  font-family: "Myriad Pro" !important;
  height: "2.5rem" !important;
  width: "150px" !important;
  border-radius: "20px" !important;
}
.navListServices {
  display: none;
  position: absolute;
  top: 35px;
  right: -30px;
  width: max-content;
  background: white;
  z-index: 1;
  /* transition: background 0.4s ease 0s; */
}
.navListServices:hover {
  display: inline;
}
.navListCarière {
  display: none;
  position: absolute;
  top: 45px;
  right: -90px;
  width: max-content;
  background: white;
  z-index: 1;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 30%);
  border-radius: 5px;
  /* transition: background 0.4s ease 0s; */
}
.navListCarière:hover {
  display: inline;
}
.navDivServices:hover .navListServices {
  display: inline;
}
.navDivCarière:hover .navListCarière {
  display: inline;
}
.navSpan {
  font-size: 16px;
  color: #393939;
  font-family: Microsoft PhagsPa;
  font-weight: 700;
  cursor: pointer;
}
.navLinkInSpan {
  font-size: 16px;
  margin-left: 3px;
}
.navLi {
  border-bottom: 1px solid #c7c6c6;
  padding: 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #5e5e5e;
  margin: 0 5px;
  cursor: pointer;
}
.navA {
  font-size: 13px;
  color: #393939 !important;
  font-family: Microsoft PhagsPa;
  font-weight: 400;
}
.navA:hover {
  color: #f9954b !important;
}
.navWeb {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
#check {
  display: none;
}
.checkbtn {
  font-size: 30px;
  float: right;
  line-height: 80px;
  cursor: pointer;
  display: none;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 992px) {
  .checkbtn {
    display: block;
  }
  .navWeb {
    /* opacity: 0; */
    position: absolute !important;
    width: 100%;
    height: fit-content;
    background: #eee;
    top: 10vh;
    left: 0;
    z-index: 1;
    text-align: left;
    display: block;
    transition: all 0.5s;
    z-index: 222222;
  }
  .logo-myser{
    width: auto !important;
  }
}
.navPop {
  margin: 7px 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Franklin Gothic Demi;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding: 5px 18px;
  background-color: #f9954b;
  border-radius: 25px;
  border: 1px solid #f9954b;
  cursor: pointer;
}
.navPop:hover {
  background-color: #fff;
  color: #f9954b;
}
.typo {
  cursor: pointer !important;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
  margin-left: 15px !important;
}
.typo:hover {
  color: #f9954b !important;
}


@media screen and (max-width:500px){
  .logo-myser{
    padding: 0 !important;
    margin-right: 0 !important;
  }
  .logo-myser img{
    width: 100px;
    height: 70px !important;
  }
  .navBtn{
    font-size: 15px !important;
    width: fit-content !important;
    height: fit-content !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}