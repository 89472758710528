.contact_map>div:first-child p {
    margin-bottom: 0 !important;
}


.no-offre {
    color: #3d3d3d;
    font-size: 23px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 92px 0;
    text-align: center;
    padding: 0px 5%;
}

.no-offre i {
    margin-right: 10px;
    font-size: 21px;
}

.inputForm {
    margin-bottom: 0.5rem;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(73, 80, 87);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    margin-top: 0.25rem;
}

.textareaForm {
    height: 150px;
    /* background-color: rgb(255, 217, 156); */
    margin-bottom: 0.75rem;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(73, 80, 87);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    margin-top: 0.5rem;
}

.labelContact {
    color: rgb(249, 149, 75);
    margin-bottom: 0.5rem;
    font-size: 1.225rem;
    float: left;
}

@media screen and (max-width:992px) {
    .contact_map {
        flex-direction: column !important;
        height: auto !important;
    }

    .contact_map>div {
        width: 100% !important;
        margin: 10px 0 !important;
        border-radius: 15px !important;
    }

    .contact_map>div:first-child {
        padding: 10px 0 !important;
    }

    .contact_map>div:first-child div {
        margin: 5px !important;
    }

    .contact_map>div:last-child {
        height: 400px !important;
    }

    .contact_map>div:last-child iframe {
        border-radius: 15px !important;
    }




}

@media screen and (max-width:700px) {
    .contact_wrapper {
        padding: 0 5% !important;
    }
}

@media screen and (max-width:700px) {
    .contact_map>div:first-child div {
        flex-direction: column;
    }

    .contact_map>div:first-child p {
        margin: 5px 0 !important;
    }


}