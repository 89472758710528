.about-ct {
    margin-bottom: 30px !important;
    text-align: center !important;
  }
  .about-ct {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: .11px;
    line-height: 38px;
    color: #000;
  }
  
  .about-ctr {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: .11px;
    line-height: 38px;
    color: rgb(241, 120, 47);
  }
  
  .about-cp {
    margin-top: 30px !important;
    text-align: justify;
  }
  
  .about-cp {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 18px;
    letter-spacing: .07px;
    line-height: 32px;
  }
  
  .imgSize{
    width: 100%;
    height: 100%;
  }

  .nousContact{
    font-weight: 700;
    fill: #ffffff;
    color: #ffffff;
    background-color: #FFA100;
    width: 200px;
    height: 3rem;
    font-weight: bold;
    font-size: 16px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #FFA100;
    border-radius: 50px 50px 50px 50px;
    box-shadow: 0px 10px 30px -8px rgb(0 0 0 / 16%);
  }

.nousContact:hover {
    color: #FFA100;
    background-color: #ffffff;
    border-color: #FFA100;
}