.label-dialog{
    float: left;
}
.input-dialog{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(73, 80, 87);
    background-clip: padding-box;
    border: 1px solid rgb(64, 150, 233);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
}
.textarea-dialog{
    height: 150px;
    margin-bottom: 0.75rem;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(73, 80, 87);
    background-clip: padding-box;
    border: 1px solid rgb(64, 150, 233);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    margin-top: 0.5rem;
}
.button-dialog{
        font-weight: 700;
        fill: #ffffff;
        color: #ffffff;
        background-color: #FFA100;
        width: 200px;
        height: 3rem;
        font-weight: bold;
        font-size: 16px;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #FFA100;
        border-radius: 50px 50px 50px 50px;
        box-shadow: 0px 10px 30px -8px rgb(0 0 0 / 16%);
        float: right;
      }
    
    .button-dialog:hover {
        color: #FFA100;
        background-color: #ffffff;
        border-color: #FFA100;
    }