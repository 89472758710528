.title{
    color: #26e0fb;
    font-weight: 600;
    font-size: 36px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 20px;
    display: inline-block;
    }

    .title::after{
        background-color: #fdaa24;
        height: 4px;
        position: absolute;
        bottom: -3px;
        right: 0;
        width: 50%;
        content: "";
      }