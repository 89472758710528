.footer {
  width: 100%;
  margin: 0px;
  color: white;
  display: flex;
  flex-direction: column;
background-color: #4096e9;
  position: relative;
  margin-top: 30px;
}
/* .footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 81, 150, 0.7);
} */
.logo-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid white;
  background-color: white;
  padding: 50px 0;
}
.footer-section {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 20px 0px;
  border-right: 1px solid white;
}
.footer-section > h4 {
  margin: 0px;
  padding: 0px;
  font-weight: 600;
  font-size: 27px;
  padding-bottom: 10px;
margin: 0 auto;
}
.footer-section > div {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 10px;
}
.footer-section p {
  margin-bottom: 6px;
  font-size: 19px;
}
.footer-section i {
  margin-right: 7px;
}
/* .footer-section > div > span {
  display: flex;
  align-items: baseline;
} */
.footer-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
}
.footer-social > h4 {
  margin: 0px;
  font-weight: 600;
  font-size: 26px;
}
.footer-social > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px 0px 10px 0px;
}

.footer-social > div:last-child  img {
  margin: 0px 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.footer-social > div:nth-child(2) > a {
  padding: 10px 0;
}
.footer-social > div:last-child  {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-social input {
  width: 100%;
  padding: 6px 0px 6px 15px;
  outline: none;
  border: 1px solid #707070;
  background: #707070;
  border-radius: 20px;
  color: white;
}

.footer > div:first-child {
  margin: 0px;
  width: 100%;
}

.footer a {
  color: white;
  text-decoration: none !important;
}
.footer a:hover {
  color: #f1782f;
}

@media screen and (max-width: 991px) {
  .footer-section > div {
    align-items: center;
  }
  .footer-section {
    align-items: center;
  }
  /* .footer-section > div {
    padding-right: 50px;
  } */
  .footer-section span {
    text-align: center;
  }
  .footer-social > h4 {
    font-size: 25px;
  }
  .footer-section > h4 {
    font-size: 25px;
  }
  .footer-section{
    border-right: none;
  }
  .logo-footer{
    border-right: none;
  }
}
