.myserv-welcome {
  max-width: 1500px;
  margin: 0 auto !important;
  padding: 1rem;
  /* background-image: url("../assets/goldenglitter1.jpg") ;
  background-repeat: no-repeat; */
}

.myserv-welcome>div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.myserv-welcome>div:nth-child(2)>img {
  max-width: 450px;
  max-height: 500px;
  width: 100%;
  height: 100%;
}

@keyframes blink {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.type-writer::after {
  content: "|";
  animation: blink 1s infinite;
}

.whoWR {
  transition: all .5s ease !important;
  background-color: #26e0fb !important;
  color: white !important;
  width: 170px !important;
  margin: 10px !important;
}

.whoWR:hover {
  color: #26e0fb !important;
  background-color: white !important;
  border: 1px solid #26e0fb !important;
}

.contactBtn {
  transition: all .5s ease !important;
  color: #fdaa24 !important;
  border: 1px solid #fdaa24 !important;
  background-color: white !important;
  width: 170px !important;
  margin: 10px !important;
}

.contactBtn:hover {
  color: white !important;
  background-color: #fdaa24 !important;
}





.services {
  max-width: 1500px;
  margin: 0 auto !important;
  padding: 30px 20px;
  text-align: center;
}

.services>h1 {
  color: #26e0fb;
  font-weight: 600;
  font-size: 34px;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
}

.services>h1::after {
  background-color: #fdaa24;
  height: 4px;
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 50%;
  content: "";
}

.services>p {
  margin: 1rem auto;
  font-weight: 500;
}

.services-list {
  margin: 0 auto !important;
  padding: 20px;
}

.services-list>div {
  margin: 10px auto;

}

.services-list>div>div {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s ease;
  height: 100%;
  cursor: pointer;
}

.services-list>div>div>img {
  width: 100%;
  height: 100%;
  max-width: 160px;
  max-height: 160px;
  margin-bottom: 10px;
}

.services-list>div>div>h3 {
  margin-bottom: 1.5rem;
  font-weight: 600;
  transition: 0.3s ease;

}

.services-list>div>div>div {
  margin-bottom: 10px;
  font-weight: 500;
  text-align: justify;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 30%);
  border-radius: 10px;
  padding: 20px 10px;
  transition: 0.1s ease;
}

.services-list>div>div:hover {
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 30%);
}

.services-list>div>div:hover>h3 {
  /* color: rgb(43, 42, 42); */
  color: white !important;
}

.services-list>div>div:hover>div {
  background-color: transparent;
  box-shadow: none;
}


.services-list>div:first-child>div>h3 {
  color: #4096e9;
}

.services-list>div:first-child>div>div {
  background-color: #bcdaf7;
}

.services-list>div:first-child>div:hover {
  background-color: #bcdaf7;
}

.services-list>div:nth-child(2)>div>h3 {
  color: #fdaa24;
}

.services-list>div:nth-child(2)>div>div {
  background-color: #fee1b3;
}

.services-list>div:nth-child(2)>div:hover {
  background-color: #fee1b3;
}

.services-list>div:nth-child(3)>div>h3 {
  color: #26e0fb;
}

.services-list>div:nth-child(3)>div>div {
  background-color: #b3f4fe;
}

.services-list>div:nth-child(3)>div:hover {
  background-color: #b3f4fe;
}

.services-list>div:nth-child(4)>div>h3 {
  color: #f1782f;
}

.services-list>div:nth-child(4)>div>div {
  background-color: #fad0b6;
}

.services-list>div:nth-child(4)>div:hover {
  background-color: #fad0b6;
}

.services-list>div:nth-child(5)>div>h3 {
  color: #0069ce;
}

.services-list>div:nth-child(5)>div>div {
  background-color: #a6cbee;
}

.services-list>div:nth-child(5)>div:hover {
  background-color: #a6cbee;
}

.services-list>div:nth-child(6)>div>h3 {
  color: #f14a2f;
}

.services-list>div:nth-child(6)>div>div {
  background-color: #fac0b6;
}

.services-list>div:nth-child(6)>div:hover {
  background-color: #fac0b6;
}

.valeurs {
  /* background-color: #fef8ed; */
  width: 100%;
}

.valeurs>h1 {
  color: #26e0fb;
  font-weight: 600;
  font-size: 34px;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 20px;
  display: inline-block;
}

.valeurs>h1::after {
  background-color: #fdaa24;
  height: 4px;
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 50%;
  content: "";
}

.valeurs>div {
  max-width: 1500px;
  margin: 0 auto !important;
  padding: 80px 0px 10px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.valeurs>div>img {
  max-width: 600px;
  max-height: 450px;
  width: 100%;
  height: 100%;
  /* box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 30%); */

}

.valeurs>div>div {
  position: absolute;
  z-index: 3;
  right: 535px;
  top: 30px;
  box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 30%);
  background-color: white;
  padding: 20px;
  font-weight: 500;
  color: rgb(48, 47, 47);
  text-align: justify;
  border: 2px solid #fdaa24;
  border-radius: 30px;
  max-width: 700px;
  font-size: 18px;
  line-height: 28px;
}


.partenaires {
  max-width: 1000px;
  margin: 40px auto !important;
}

.partenaires>h1 {
  color: #26e0fb;
  font-weight: 600;
  font-size: 34px;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
  display: inline-block;
}

.partenaires>h1::after {
  background-color: #fdaa24;
  height: 4px;
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 50%;
  content: "";
}

.partenaires>div {
  margin: 0 !important;
  padding: 20px 10px;
}

.partenaires>div>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partenaires>div>div>div {
  border: 1px solid #f1782f;
  border-radius: 5px;
  width: 220px;
  height: 120px;
  transition: .5s;
  background-color: #fff;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.5s;
}

.partenaires>div>div>div:hover {
  border: 1px solid #4096e9;
  box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 30%);

}

.partenaires>div>div>div>a {
  text-decoration: none;
  transition: 0.4s;
}

.partenaires>div>div>div img {
  max-width: 190px;
  max-height: 90px;
  width: 100%;
  height: 100%;
}

/* .myserv-carou img{
  height: 700px;
} */

@media screen and (max-width:992px) {
  .valeurs>div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }

  .valeurs>div>div {
    position: relative;
    z-index: 3;
    margin: 30px 20px;
    right: inherit;
    top: inherit;
    background-color: white;
    padding: 20px;
    font-weight: 500;
    color: rgb(48, 47, 47);
    text-align: justify;
    border: 2px solid #fdaa24;
    max-width: 700px;
    font-size: 18px;
    line-height: 28px;
  }



}

@media screen and (max-width:500px) {
  .myserv-welcome>div:nth-child(2)>img {
    max-width: 340px;
    max-height: 380px;
  }

  .myserv-welcome {
    padding-top: 0;
  }

  .myserv-welcome>div:first-child>P {
    font-size: 23px !important;
  }

  .carousel .thumbs-wrapper {
    margin-bottom: 0 !important;
  }

  .services-list {
    padding: 20px 0;
  }

  .services-list>div>div>img {
    max-width: 130px;
    max-height: 130px;
  }

  .valeurs>div>img {
    max-height: 350px;
  }

  .valeurs>div>div {
    font-size: 16px;
  }

  .services>h1,
  .partenaires>h1,
  .valeurs>h1 {
    font-size: 26px;
  }

}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1;
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fade-in.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.fade-in.two {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fade-in.three {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.fade-in.four {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  animation-delay: 2s;
}

.fade-in.five {
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.fade-in.six {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  animation-delay: 3s;
}
