.map-tab {
  width: 100%;
  padding: 10px;
  display: flex;
  grid-template-columns: 30% 30%;
  place-items: center;
  transition: 1.5s ease-in-out;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column: 1/2;
  justify-content: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin-top: 2rem;
  justify-content: space-evenly;
}
.map-tab-div {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 1px 5px 1px rgb(0 0 0 / 30%);
  cursor: pointer;
  margin: 20px;
}
.map-tab-div2 {
  min-width: 250px;
  min-height: 250px;
  display: grid;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 1px 5px 1px rgb(0 0 0 / 30%);
  /* cursor: pointer; */
  margin: 20px;
  text-align: left;
  padding: 10px;
}
.map-tab-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
.map-tab-title2 {
  font-size: 17px;
  font-weight: 600;
  /* margin-bottom: 0.5rem; */
  margin-top: 0;
  display: flex;
  align-items: center;
}
.conge-values {
  margin-top: 0;
  margin-left: 5px;
  font-weight: 400;
  font-size: 18px;
}
