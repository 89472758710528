.service-myserv{
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto !important;
  padding-top: 70px;
}
.service-myserv>h1{
  color: #26e0fb;
  font-weight: 600;
  font-size: 36px;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 20px;
  display: inline-block;
  }
  .service-myserv>h1::after{
    background-color: #fdaa24;
    height: 4px;
    position: absolute;
    bottom: -3px;
    right: 0;
    width: 50%;
    content: "";
  }
.service-myserv>div:nth-child(even){
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 30px 0px;
}
.service-myserv>div:nth-child(even)>div:first-child{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  flex-direction: column;
  text-align: center;
}

.service-myserv>div:nth-child(even)>div:first-child>img{
width: 100%;
height: 100%;
max-width: 200px !important;
max-width: 200px;
margin: 20px auto;
}

.service-myserv>div:nth-child(even)>div:first-child>h3{
  font-weight: 600;
}

.service-myserv>div:nth-child(even)>div:last-child{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.service-myserv>div:nth-child(even)>div:last-child>div{
  font-weight: 500;
  text-align: justify;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 30%);
  border-radius: 10px;
  padding: 20px 10px;
  transition: 0.1s ease;
  line-height: 1.5;
}


.service-myserv>div:nth-child(odd){
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 30px 0px;
}
.service-myserv>div:nth-child(odd)>div:last-child{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  flex-direction: column;
  text-align: center;
}

.service-myserv>div:nth-child(odd)>div:last-child>img{
width: 100%;
height: 100%;
max-width: 200px !important;
max-width: 200px;
margin: 20px auto;
}

.service-myserv>div:nth-child(odd)>div:last-child>h3{
  font-weight: 600;
}

.service-myserv>div:nth-child(odd)>div:first-child{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.service-myserv>div:nth-child(odd)>div:first-child>div{
  font-weight: 500;
  text-align: justify;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 30%);
  border-radius: 10px;
  padding: 20px 10px;
  transition: 0.1s ease;
  line-height: 1.5;
}

.service-myserv>div:nth-child(2)>div:last-child>div{
  background-color: #bcdaf7;
}
.service-myserv>div:nth-child(3)>div:first-child>div{
  background-color: #fee1b3;
}
.service-myserv>div:nth-child(4)>div:last-child>div{
  background-color: #b3f4fe;
}

.service-myserv>div:nth-child(5)>div:first-child>div{
  background-color: #fdefe6;
}

.service-myserv>div:nth-child(6)>div:last-child>div{
  background-color: #ebf4fb;
}
.service-myserv>div:nth-child(7)>div:first-child>div{
  background-color: #fde9e6;
}

@media screen and (max-width:900px) {
  .service-myserv>div:nth-child(even){
    display: grid;
    grid-template-columns: 100%;
    height: -moz-fit-content;
    height: fit-content;
    grid-template-areas:
    "image"
    "text"
  }
  .service-myserv>div:nth-child(even)>div:first-child{
    grid-area: image;
    padding-bottom: 20px;
  }
  .service-myserv>div:nth-child(even)>div:first-child>img{
    margin: 0 0 10px;
  }
  
  .service-myserv>div:nth-child(even)>div:last-child{
    grid-area: text;
    padding-bottom: 20px;
  }

  .service-myserv>div:nth-child(odd){
    display: grid;
    grid-template-columns: 100%;
    height: -moz-fit-content;
    height: fit-content;
    grid-template-areas:
    "image"
    "text"
  }
  .service-myserv>div:nth-child(odd)>div:last-child{
    grid-area: image;
    padding-bottom: 20px;
  }
  .service-myserv>div:nth-child(odd)>div:last-child>img{
    margin: 0 0 10px;
  }
  
  .service-myserv>div:nth-child(odd)>div:first-child{
    grid-area: text;
    padding-bottom: 20px;
  }

}

@media screen and (max-width:500px) {
.service-myserv>h1{
  font-size: 28px;
}
.service-myserv>div:nth-child(even)>div:first-child>img{
max-width: 180px !important;
max-height: 180px;
}
.service-myserv>div:nth-child(even)>div:last-child{
  padding: 0;
}

.service-myserv>div:nth-child(odd)>div:last-child>img{
  max-width: 180px !important;
  max-height: 180px;
  }
  .service-myserv>div:nth-child(odd)>div:first-child{
    padding: 0;
  }

.title_wrapp{
  height: fit-content !important;
padding: 10px 20px !important;
}
.title_wrapp>h1{
  font-size: 26px !important;
}

}

